import { Route, Routes } from "react-router-dom";
import { useAuthService } from "./Context/AuthContext";
import { Toaster } from "react-hot-toast";
import DashBoard from "./Pages/DashBoard";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import PrivateRoute from "./Router/PrivateRoute";
import Header from "./Components/Dashboard/Header/Header";
import styled from "styled-components";
import ProfilePage from "./Pages/ProfilePage";
import ProjectContextProvider from "./Context/ProjectsContext";
import ModelPage from "./Pages/ModelPage";
import ManagePicturesPage from "./Pages/ManagePicturesPage";
import SpinnerComponent from "./Components/Generic/SpinnerComponent";
import { useState } from "react";
import WebPage from "./Pages/WebPage";
function App() {
  const { isAuthenticated } = useAuthService();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Toaster />
      <SpinnerComponent isLoading={isLoading}>
        <Content>
          {isAuthenticated && window.location.pathname !== "/" && <Header />}
          <Routes>
            <Route path="/" element={<WebPage />} />

            <Route
              path="/login"
              element={<LoginPage setIsLoading={setIsLoading} />}
            />

            <Route
              path="/register"
              element={<RegisterPage setIsLoading={setIsLoading} />}
            />

            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <ProjectContextProvider>
                    <DashBoard setIsLoading={setIsLoading} />
                  </ProjectContextProvider>
                </PrivateRoute>
              }
            />

            <Route
              path="/home/profile"
              element={
                <PrivateRoute>
                  <ProfilePage setIsLoading={setIsLoading} />
                </PrivateRoute>
              }
            />

            <Route
              path="/home/model/:id"
              element={
                <PrivateRoute>
                  <ProjectContextProvider>
                    <ModelPage setIsLoading={setIsLoading} />
                  </ProjectContextProvider>
                </PrivateRoute>
              }
            />

            <Route
              path="/home/model/:id/manage-images"
              element={
                <PrivateRoute>
                  <ProjectContextProvider>
                    <ManagePicturesPage setIsLoading={setIsLoading} />
                  </ProjectContextProvider>
                </PrivateRoute>
              }
            />

          </Routes>
        </Content>
      </SpinnerComponent>
    </>
  );
}

export default App;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
