import React from 'react'
import styled from 'styled-components'
import { color } from '../../Assets/styles/colors'

const LoadBar = ({ finishedItems, totalItems, completed }) => {
    return (
        <Container>
            <Details>
                <p>Uploading {finishedItems} of {totalItems}</p>
            </Details>
            <BarProgress completed={completed}>
            </BarProgress>
        </Container>
    )
}

export default LoadBar
const Container = styled.div`
display:flex;
width: 100%;
height:8px;
border-radius:10px;
position:absolute;
gap:5px;
top:60px;
align-items:center;
`
const Details = styled.div`
min-width:fit-content;
`
const BarProgress = styled.div`
  background:${color.primaryGreen};
  width: ${(props) => props.completed}%;
  height: 100%;
  border-radius:10px;
  position:relative;
  display:flex;
  justify-content:center;
`
