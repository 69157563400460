const countryCodes = require('country-codes-list')
const allData = countryCodes.all();
const sortedList = allData.sort((a, b) => a.countryNameEn > b.countryNameEn ? 1 : -1)
export const myCountryCodesObject = sortedList.map((item, index) => {
    return ({
        countryName: item.countryNameEn,
        countryCode: item.countryCode,
        flag: item.flag,
        countryCallingCode: item.countryCallingCode
    })

})
