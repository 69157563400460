import { POST } from "../API";
let source = null;

export const updateUserApi = (name, email, fcm_token = null, onSuccess, onError) => {
    const data = {
        name: name,
        email: email,
    };

    if (fcm_token) {
        data.fcm_token = fcm_token
    }

    source = POST(`/api/v1/users/update`, data, onSuccess, onError, true, source);
};