import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../Assets/styles/colors";
import "../Assets/styles/style.css";


const RegisterPage = ({ setIsLoading }) => {
  const initialInputValues = {
    name: "",
    email: "",
  };

  const [readyToContinue, setReadyToContinue] = useState(false);
  const [inputValues, setInputvalues] = useState(initialInputValues);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleButton = () => {

  }

  const checkFields = () => {
    if (inputValues.name.length < 3 || inputValues.email.length < 3) {
      setReadyToContinue(false)
    } else {
      setReadyToContinue(true)
    }

  };

  useEffect(() => {
    checkFields()
  }, [inputValues])

  return (

    <Wrapper>
      <LoginCard>
        <SmallTitle>
          <h1>PROFILE SETUP</h1>
        </SmallTitle>
        <InputGroup>
          <h1>What's your name?</h1>
          <input
            name="name"
            type="text"
            placeholder="Type your name"
            value={inputValues.name}
            onChange={(e) => inputChangeHandler(e)}
          ></input>
        </InputGroup>
        <InputGroup>
          <h1>What's your e-mail?</h1>
          <input
            name="email"
            type="email"
            placeholder="Type your e-mail"
            value={inputValues.email}
            onChange={(e) => inputChangeHandler(e)}
          ></input>
        </InputGroup>

        <GreenButton
          disabled={true}
          onClick={(e) => handleButton(e)}
        >

          Continue
        </GreenButton>
      </LoginCard>
    </Wrapper>

  );
};

export default RegisterPage;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background: ${color.darkColor};
`;
const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 0 75px;
  border-radius: 12px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  background: #fbfbfb;
  height: 765px;
  width: 576px;
`;

const GreenButton = styled.button`
  padding: 15px 0;
  color: ${color.white};
  background: ${color.primaryGreen};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;

  :disabled{
    cursor:inherit;
    opacity:0.5;
  }
`;

const SmallTitle = styled.div`
  display: flex;
  h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    color: ${color.primaryGreen};
  }
`;
const InputGroup = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${color.darkColor};
    margin-bottom: 30px;
  }
  input {
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 15px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
  }
`;
