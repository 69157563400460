// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCv5ItsymGi8KoV5H4sWiyFg-z3Uj7StGo",
    authDomain: "dobjects-2c14d.firebaseapp.com",
    projectId: "dobjects-2c14d",
    storageBucket: "dobjects-2c14d.appspot.com",
    messagingSenderId: "376558166718",
    appId: "1:376558166718:web:e1159df2714060503574ef",
    measurementId: "G-L2R16Y1DMM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);