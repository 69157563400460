import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../Assets/styles/colors";
import "../Assets/styles/style.css";
import { useAuthService } from "../Context/AuthContext";

import { ReactComponent as Logo } from "../Assets/icons/logo-normal.svg";
import { auth } from "../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import {
    notifyError,
    notifySucces,
} from "../Components/Generic/Notifications";
import { useNavigate } from "react-router-dom";
import { PhoneNumberInput } from "../Components/Auth/PhoneNumberInput";
import { CodeNumberInput } from "../Components/Auth/CodeNumberInput";
import { NewUserInput } from "../Components/Auth/NewUserInput";
import SpinnerComponent from "../Components/Generic/SpinnerComponent";



const LoginPage = () => {

    const { signInWithPhone, verifyOtpCodeAndSignIn, isAuthenticated, firebaseToken, getCurrentUser, createUser } =
        useAuthService();
    const [codeSent, setCodeSent] = useState(false);
    const [wrongCode, setWrongCode] = useState(false);
    const [addNewUser, setAddNewUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [readyToVerify, setReadyToverify] = useState(false);

    const navigate = useNavigate();

    const captchaVerify = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        // ...
                    },
                    "expired-callback": () => {
                        window.recaptchaVerifier.recaptcha.reset();
                        // Response expired. Ask user to solve reCAPTCHA again.
                        // ...
                    },
                }
            );
        }
    };

    const handleSignIn = (number) => {
        captchaVerify();
        setIsLoading(true);
        const appVerifier = window.recaptchaVerifier;
        signInWithPhone(
            number,
            appVerifier,
            function (confirmationResult) {
                window.confirmationResult = confirmationResult;
                setIsLoading(false);
                setCodeSent(true);
                notifySucces("Code sent");
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something went wrong, please try again!");
                console.log(error, "err");
            }
        );
    };

    const handleVerifyCode = (otp) => {
        let confirmationRes = window.confirmationResult;
        setIsLoading(true);
        verifyOtpCodeAndSignIn(
            confirmationRes,
            otp,
            function (response) {
                setWrongCode(false);
                setIsLoading(false);

                getCurrentUser(
                    function (response) {

                    },
                    function (error) {

                        if (error?.response?.status === 404) {
                            setAddNewUser(true);
                        }
                    }
                )
            },
            function (error) {
                setWrongCode(true);

                console.log(error);
                notifyError("Something wrong");
                setIsLoading(false);
            }
        );
    };

    const handleCreateNewUser = (username, token) => {
        setIsLoading(true);
        createUser(
            username,
            token,
            function (response) {
                setIsLoading(false)
                notifySucces("User created");
                console.log(response)
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong")
                console.log(error)
            }
        )
    }

    const handleResendOTP = () => {
        window.location.reload(false)
    }



    useEffect(() => {
        if (isAuthenticated) {
            navigate("/home");
        }
    }, [isAuthenticated]);

    return (
        <SpinnerComponent isLoading={isLoading}>
            <Wrapper>
                <LoginCard>
                    <LogoContainer>
                        {" "}
                        <Logo />
                        <h1>ARMERC</h1>
                    </LogoContainer>
                    {!codeSent && !addNewUser && (
                        <PhoneNumberInput
                            handleSignIn={handleSignIn}
                            setIsLoading={setIsLoading}
                            setCodeSent={setCodeSent}
                        />
                    )}
                    {codeSent && !addNewUser && (
                        <CodeNumberInput
                            setIsLoading={setIsLoading}
                            handleVerifyCode={handleVerifyCode}
                            wrongCode={wrongCode}
                            handleResendOTP={handleResendOTP}
                            readyToVerify={readyToVerify}
                            setReadyToverify={setReadyToverify}
                        />
                    )}
                    {addNewUser && (
                        <NewUserInput
                            setIsLoading={setIsLoading}
                            handleCreateNewUser={handleCreateNewUser}
                        />
                    )}
                </LoginCard>
            </Wrapper>
        </SpinnerComponent>
    );
};

export default LoginPage;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background: ${color.darkColor};
  width:100%;
`;
const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 0 75px;
  border-radius: 12px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  background: #fbfbfb;
  height: 765px;
  width: 576px;
`;

const LogoContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
h1{
    font-family: PT Sans;
font-size: 20px;
font-weight: 700;
line-height: 25px;
text-align: left;
color:${color.darkColor};
}
`;
