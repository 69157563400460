import React, { useState } from 'react'
import { SyncLoader } from 'react-spinners'
import "../../Assets/styles/style.css"
import styled from 'styled-components'
import { color } from '../../Assets/styles/colors'

const SpinnerComponent = ({ children, isLoading }) => {

    return (
        <>
            {isLoading &&
                <Container>
                    <SyncLoader color={color.primaryGreen} className='loader' loading={isLoading} />
                </Container >}
            {children}
        </>
    )



}
export default SpinnerComponent

const Container = styled.div`
position:absolute;
min-height:calc(100vh - 120px);
display:flex;
justify-content:center;
align-items:center;
width:100%;
background:transparent;
z-index:5;
`