import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styled from "styled-components";
import { color } from "../../Assets/styles/colors";
import ProfileOption from "../Profile/ProfileOption";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/Profile/delete-profile-icon.svg"

const DeleteConfirmation = ({ handleRemoveItem, variant, disableButton }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        handleRemoveItem();
        setOpen(false);
    };

    const DisplayVariant = ({ variant }) => {
        let displayItem = (
            <DeleteButton onClick={() => handleClickOpen()} >
                Delete model
            </DeleteButton>
        )
        if (variant === "delete_profile") {
            displayItem = (
                <ProfileOption
                    action={handleClickOpen}
                    img={<DeleteIcon />}
                    text={"Delete account"}
                    textStyle={{ color: `${color.errorColor}` }}
                />
            )

        }

        return (
            displayItem
        )
    }

    const DisplayDialogVariant = ({ variant }) => {
        let displayDialog = (<DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item?
        </DialogContentText>)

        return displayDialog
    }

    return (
        (<div style={{ width: "100%" }}>
            <DisplayVariant variant={variant} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)


    );
};

export default DeleteConfirmation;
const NormalButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
color:${color.white};
font-family: PT Sans;
font-size: 12px;
font-weight: 700;
line-height: 16px;
text-align: center;
padding:10px;
border-radius:68px;
border:none;
background:${color.darkColor};
min-width:200px;
min-height:38px;
width:100%;
cursor:pointer;
&:disabled{
    opacity:0.5;
}
`
const DeleteButton = styled(NormalButton)`
background:${color.white};
border:1px solid ${color.errorColor};
color:${color.errorColor};

`

const OptionContainer = styled.div`
display:flex;
padding:12px 35px;
border:1px solid ${color.primaryGreen};
border-radius:10px;
align-items:center;
gap:15px;
cursor:pointer;

svg{
    height:50px;
    width:50px;
}

h1{
    font-family: PT Sans;
font-size: 18px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0px;
text-align: left;

}
`