import React from 'react'
import { useAuthService } from '../Context/AuthContext'
import LoginPage from '../Pages/LoginPage';


const PrivateRoute = ({ children }) => {
    const { user } = useAuthService();

    if (user === undefined) return <div></div>
    if (user === null) return <LoginPage />
    return (
        <>{children}</>
    )

}

export default PrivateRoute