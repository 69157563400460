
import React from 'react';
import '../Assets/styles/webPageStyle.css'



const WebPage = () => {

    function showMenu() {
        document.getElementById("dropdown-content").classList.toggle("show");
        document.getElementById("full-container").classList.toggle("disableScroll");
    }

    var myScrollFunc1 = function () {
        var y = window.scrollY;
        if (y >= 600) {
            document.getElementById("logo").className = "logo-desktop show";
        } else {
            document.getElementById("logo").className = "logo-desktop hide";
        }
    };

    window.addEventListener("scroll", myScrollFunc1);

    var myScrollFunc2 = function () {
        var y = window.scrollY;
        if (y >= 103) {
            document.getElementById("desktop-nav").className =
                "navigation-bar-desktop full-nav";
        } else {
            document.getElementById("desktop-nav").className =
                "navigation-bar-desktop transparent-nav";
        }
    };

    window.addEventListener("scroll", myScrollFunc2);

    document.addEventListener("DOMContentLoaded", function () {
        // Get the current point in time
        const timestamp = new Date();

        // advance by 24 hours, to get to tomorrow
        timestamp.setTime(timestamp.getTime() + 24 * 60 * 60 * 1000);

        // format and output the result
        const locale = undefined; // use the user's current locale (language/region)
        document.getElementById("spanDate").innerHTML = timestamp.toLocaleString(
            locale,
            {
                timeZone: "America/Chicago", // use the US Central Time Zone
                year: "numeric", // include the year as a number
            }
        );

        document.getElementById("spanDate2").innerHTML = timestamp.toLocaleString(
            locale,
            {
                timeZone: "America/Chicago", // use the US Central Time Zone
                year: "numeric", // include the year as a number
            }
        );
    });


    return (
        <div class="full" id="full-container">
            <nav class="navigation-bar-mobile">
                <p class="title1">AR<b class="merch1">MERC</b></p>
                <div class="dropdown">
                    <button onClick={() => showMenu()} class="menu-button">
                        <img class="gr1243" src="PlainHtmlSite/img/Group 1243.svg" />
                    </button>
                </div>

                <div class="mobile-menu-links-container" id="dropdown-content">
                    <div class="menu-title">
                        <p class="title1">AR<b class="merch1">MERC</b></p>
                        <button onClick={() => showMenu()} class="menu-button">
                            <img class="gr1243" src="PlainHtmlSite/img/Group 1243.svg" />
                        </button>
                    </div>

                    <div class="menu-content">
                        <a class="blog" href="https://relleasy.notion.site/39ad4aa23af446a39ed3fd87a5f044a8?v=e443a254fcd345bc80c09574e33252f0" target="_blank">Blog</a>
                        <p class="p1-links">/ see how to use the app</p>
                        <a class="contact" href="mailto:office@appssemble.com">Contact</a>
                        <p class="p1-links">/ need to reach us?</p>
                        <a class="download" href="https://itunes.apple.com/app/id6470124593" target="_blank">Download App</a>
                        <p class="p1-links">/ do it now and thank us later</p>
                    </div>
                </div>
            </nav>

            <header>
                <div class="mobile-header">
                    <div class="mobile-header-1">
                        <img class="gr36151" src="PlainHtmlSite/img/Group 36151.svg" />
                        <p class="p-top"><b class="showcase">Showcase</b> your product as an incredibly detailed
                            <b class="d-model">3D model.</b>
                        </p>
                    </div>
                    <img class="gr36149" src="PlainHtmlSite/img/mobile-header-image.png" />
                </div>
                <div class="desktop-header">
                    <nav class="navigation-bar-desktop transparent-nav" id="desktop-nav">
                        <p class="logo-desktop hide" id="logo">AR<b class="merch1">MERC</b></p>
                        <div class="navigation-bar-desktop-right">
                            <a class="blog" href="https://relleasy.notion.site/39ad4aa23af446a39ed3fd87a5f044a8?v=e443a254fcd345bc80c09574e33252f0" target="_blank">Blog</a>
                            <a class="contact" href="mailto:office@appssemble.com">Contact</a>
                            <a class="login" href="/home">Login</a>
                        </div>
                    </nav>
                    <div class="group">
                        <img class="gr36140" src="PlainHtmlSite/img/header-image-left.png" alt="header" />
                        <div class="header-text">
                            <p class="name">ARMERC</p>
                            <p class="p1">Showcase your product as an incredibly<br />detailed 3D model.</p>
                        </div>
                    </div>
                    <img class="gr36134" src="PlainHtmlSite/img/header-image-right.png" alt="group36134" />
                </div>
            </header>

            <main>

                <section class="section1">
                    <p class="p2">How it works...</p>
                    <div class="division">
                        <div class="div1">
                            <p class="pd1">01</p>
                            <p class="pd2">Choose scan mode</p>
                            <p class="pd3">You can choose from two different scanning processes.</p>
                        </div>
                        <div class="div2">
                            <p class="pd1">02</p>
                            <p class="pd2">First scan</p>
                            <p class="pd3">Press the button and take a 360° scan around your item. </p>
                        </div>
                        <div class="div3">
                            <p class="pd1">03</p>
                            <p class="pd2">Second scan</p>
                            <p class="pd3">This time from a different angle than last time. </p>
                        </div>
                        <div class="div4">
                            <p class="pd1">04</p>
                            <p class="pd2">Take pics</p>
                            <p class="pd3">Take some pics of the most detailed elements.</p>
                        </div>
                    </div>
                    <div class="division2">
                        <p class="pd4">Bringing Your <b class="product">Product</b> to Life</p>
                        <p class="pd5">Transforming iPhone Photos into Stunning 3D Models for AR in minutes.
                            <br />Select from 2 Modes: Half Scan or Full Scan.
                        </p>
                        <p class="pd5-mobile"><b class="pd5-mobile-b">/</b> Transforming iPhone Photos into Stunning 3D
                            Models for AR in minutes.</p>
                        <div class="group2">
                            <img class="gr36135" src="PlainHtmlSite/img/image-section1.png" alt="gr36135" />
                            <img class="gr36139" src="PlainHtmlSite/img/Group 36158.png" alt="gr36139" />
                        </div>
                        <img class="gr36136" src="PlainHtmlSite/img/Group 36136.png" alt="gr36136" />
                        <img class="gr36150" src="PlainHtmlSite/img/Group 36150.svg" alt="gr36150" />
                    </div>
                    <div class="mobile-container-section2">
                        <img class="elipse1-mobile" src="PlainHtmlSite/img/Ellipse 87.png" />
                        <p class="s1">From Shots to Marvels</p>
                        <p class="s1-2">Processed <b class="s1-3">3D models</b> will each have a dedicated screen for users
                            to view details, stats, photos, access AR features, and share the model - despite AR being
                            relatively unfamiliar to many online shoppers.</p>
                        <img class="screenshot-1" src="PlainHtmlSite/img/screenshot-1.png" />
                        <p class="s2">Edit Model Details</p>
                        <p class="s3">You can edit your model, change the name and the description or delete it if you do
                            not need it anymore. </p>
                        <img class="screenshot-2" src="PlainHtmlSite/img/screenshot-2.png" />
                        <img class="elipse2-mobile" src="PlainHtmlSite/img/Ellipse 89.png" />
                        <p class="s2">Model Stats</p>
                        <p class="s3">We give you an easy and concise way to see buyers' engagement with your products.
                            <br />
                            You can see daily, monthly, and total clicks of your 3D model link.
                        </p>

                        <img class="screenshot-3" src="PlainHtmlSite/img/screenshot-3.png" />
                        <p class="s2">Model Link</p>
                        <p class="s3">We give you a link to your 3D model, and you can add it to any sale description to enhance your products. </p>
                    </div>

                </section>

                <section class="section2">
                    <img class="up" src="PlainHtmlSite/img/Ellipse 86.png" />
                    <div class="ph">
                        <p class="ps2">From Shots to Marvels</p>
                    </div>
                    <div class="section22">
                        <img class="gr36137" src="PlainHtmlSite/img/Group 36155.png" />
                        <div class="d1">
                            <p class="p1d1">3D Model Screen</p>
                            <p class="p2d1">Each processed 3D model will have a separate screen where the user can see the
                                model details, stats, and photos.
                                <br />
                                <br />
                                From this screen, the user can access the AR feature and the shareable model link. The AR
                                feature helps you place 3D objects in the real world.
                                <br />
                                <br />
                                Because AR is a relatively new technology, most users don’t think to actively look for it
                                when they browse products online.
                            </p>
                        </div>
                        <div class="d2">
                            <p class="p1d2">Model Photos</p>
                            <p class="p2d2">If your model misses some details, no worries, you can add new photos at any
                                time to enhance all of your model’s details.<br /><br />

                                You also can remove any picture you like.</p>
                        </div>
                        <div class="d3">
                            <p class="p1d3">Edit Model Details</p>
                            <p class="p2d3">You can edit your model, change the name and the description or delete it if you
                                do not need it anymore. </p>
                        </div>
                        <div class="d4">
                            <p class="p1d4">Model Stats</p>
                            <p class="p2d4">We give you an easy and concise way to see buyers' engagement with your
                                products.<br /><br />

                                You can see daily, monthly, and total clicks of your 3D model link.
                            </p>
                        </div>
                        <div class="d5">
                            <p class="p1d5">Model Link</p>
                            <p class="p2d5">We give you a link to your 3D model, and you can add it to any sale description
                                to enhance your products. </p>
                        </div>
                    </div>
                    <img class="down" src="PlainHtmlSite/img/Ellipse 84.png" />
                </section>

                <section class="mobile-container-section3">
                    <p class="section3-p1">/ AR INSIGHTS </p>
                    <p class="section3-p2">Realistic 3D models that are optimized for AR.</p>
                    <img class="gr36138-1" src="PlainHtmlSite/img/image-section3.png" />
                </section>
                <section class="section3">
                    <div class="left">
                        <p class="left1">/ AR INSIGHTS </p>
                        <p class="left2">Realistic 3D models that are optimized for AR.</p>
                        <p class="left3">We created a quick walkthrough to increase awareness of the AR feature, show its
                            potential and guide users through the novel interactions of a simple AR experience so they can
                            learn how to use the AR feature.</p>
                    </div>
                    <div class="right">
                        <img class="gr36138" src="PlainHtmlSite/img/Group 36156.png" />
                    </div>
                </section>
            </main>

            <footer class="desktop-footer">
                <div class="footer-left">
                    <img class="vector" src="PlainHtmlSite/img/Vector.svg" />
                    <p class="left-footer1">ARMERC</p>
                    <p class="left-footer2">Copyright © <span id="spanDate"></span> <b>ARMERC</b>.<br />
                        All rights reserved.</p>
                </div>
                <div class="footer-right">
                    <div class="social">
                        <p>Social</p>
                        <a href="https://www.instagram.com/armerc_3d/" target="_blank">Instagram</a>
                    </div>
                    <div class="support">
                        <p>Support</p>
                        <a href="https://relleasy.notion.site/Privacy-Policy-6da8768b9bbc40efb4141ff2490d166f" target="_blank">Privacy policy</a>
                        <a href="https://relleasy.notion.site/Terms-Conditions-cd898afcbf3244de9c652172c0159161" target="_blank">Terms & Conditions</a>
                        <a href="mailto:office@appssemble.com">Contact</a>
                    </div>
                </div>
            </footer>

            <footer class="mobile-footer">
                <div class="mobile-footer-1">
                    <p class="title1">AR<b class="merch1">MERC</b></p>
                    <img class="gr36144" src="PlainHtmlSite/img/Group 36144.svg" />
                </div>
                <div class="mobile-footer-2">
                    <p class="mobile-footer-2-social">SOCIAL</p>
                    <div class="links-social">
                        <a href="https://www.instagram.com/armerc_3d/" target="_blank">Instagram</a>
                    </div>
                </div>
                <div class="mobile-footer-3">
                    <p class="mobile-footer-3-support">SUPPORT</p>
                    <div class="links-support">
                        <a href="https://relleasy.notion.site/Privacy-Policy-6da8768b9bbc40efb4141ff2490d166f" target="_blank">Privacy policy</a>
                        <img src="PlainHtmlSite/img/Ellipse 2.svg" />
                        <a href="https://relleasy.notion.site/Terms-Conditions-cd898afcbf3244de9c652172c0159161" target="_blank">T & C</a>
                        <img src="PlainHtmlSite/img/Ellipse 2.svg" />
                        <a href="mailto:office@appssemble.com">Contact</a>
                    </div>
                </div>
                <div class="mobile-footer-4">
                    <p>Copyright © <span id="spanDate2"></span> ARMERC <br />
                        All rights reserved.</p>
                </div>
            </footer>

        </div>

    );
};

export default WebPage;