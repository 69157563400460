
import { createContext, useState, useContext, useCallback } from "react";
import { createProjectApi } from "../API/projects/createProject";
import { getProjectsApi } from "../API/projects/getProjects";
import { editProjectApi } from "../API/projects/editProject";
import { addImageToProjectApi } from "../API/projects/addImagesToProject";
import { deleteImageFromProjectApi } from "../API/projects/deleteImageFromProject";
import { getProjectApi } from "../API/projects/getProject";
import { deleteProjectApi } from "../API/projects/deleteProject";
import { viewObjectApi } from "../API/objects/viewObject";



export const ProjectsContext = createContext({
    projects: [],
    createProject: () => { },
    getProjects: () => { },
    getProject: () => { },
    editProject: () => { },
    addImageToProject: () => { },
    deleteImageFromProject: () => { },
    deleteProject: () => { },
    viewObject: () => { },
});

const ProjectsContextProvider = ({ children }) => {
    const [projects, setProjects] = useState([])

    const getProjects = useCallback(async (
        onSucces,
        errorCallback
    ) => {
        try {
            await getProjectsApi(
                function (response) {
                    setProjects(response.data.projects);
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const getProject = useCallback(async (
        id,
        onSucces,
        errorCallback
    ) => {
        try {
            await getProjectApi(
                id,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const createProject = useCallback(async (
        name,
        description,
        model_type,
        onSucces,
        errorCallback

    ) => {
        try {
            await createProjectApi(
                name,
                description,
                model_type,
                function (response) {

                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const editProject = useCallback(async (
        projectId,
        name,
        description,
        onSucces,
        errorCallback

    ) => {
        try {
            await editProjectApi(
                projectId,
                name,
                description,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const addImageToProject = useCallback(async (
        id,
        data,
        onSucces,
        errorCallback,
        onProgress = () => { }
    ) => {
        try {
            await addImageToProjectApi(
                id,
                data,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                },
                function (progress) {
                    onProgress(progress);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const deleteImageFromProject = useCallback(async (
        id,
        imageId,
        onSucces,
        errorCallback

    ) => {
        try {
            await deleteImageFromProjectApi(
                id,
                imageId,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const deleteProject = useCallback(async (
        id,
        onSucces,
        errorCallback

    ) => {
        try {
            await deleteProjectApi(
                id,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const viewObject = useCallback(async (
        id,
        onSucces,
        errorCallback

    ) => {
        try {
            await viewObjectApi(
                id,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    return (
        <ProjectsContext.Provider value={{
            createProject,
            getProjects,
            getProject,
            projects,
            editProject,
            addImageToProject,
            deleteImageFromProject,
            deleteProject,
            viewObject,
        }}>
            {children}
        </ProjectsContext.Provider>
    );
};

export const useProjectsService = () => useContext(ProjectsContext);

export default ProjectsContextProvider;
