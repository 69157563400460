import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SmallPhotoListItem from './SmallPhotoListItem';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../../../Assets/styles/style.css";

import ImageViewer from 'react-simple-image-viewer';
import { ReactComponent as SwiperArrow } from '../../../Assets/icons/Model/swiper-arrow.svg';
import { color } from '../../../Assets/styles/colors';
const ModelSmallSwiper = ({ images }) => {

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [maxSwiperHeight, setMaxSwiperHeight] = useState(parseInt(windowHeight - 241));
    const [slidersPerView, setSlidersPerView] = useState(parseInt(maxSwiperHeight / 150));

    const setWindowDimensions = () => {
        setWindowHeight(window.innerHeight);
    };

    const calculateMaxHeight = () => {
        setMaxSwiperHeight(parseInt(windowHeight - 241));
    };

    const calculateSlidersPerView = () => {
        setSlidersPerView(parseInt(maxSwiperHeight / 150));
    };

    const swipeNext = () => {
        let swiper = document.querySelector("#small-model-swiper").swiper;
        swiper.slideNext();
    };

    const swipeBack = () => {
        let swiper = document.querySelector("#small-model-swiper").swiper;
        swiper.slidePrev();
    };


    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imagesFull, setImagesFull] = useState([]);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const initializeImagesFull = () => {
        let newImages = []
        images?.forEach(element => {
            newImages.push(element.viewable_file_url)
        });
        setImagesFull(newImages);
    }

    useEffect(() => {
        initializeImagesFull()
    }, [images])

    useEffect(() => {
        window.addEventListener("resize", setWindowDimensions);
        return () => {
            window.removeEventListener("resize", setWindowDimensions);
        };
    }, []);

    useEffect(() => {
        calculateMaxHeight();
        calculateSlidersPerView();
    }, [windowHeight])


    return (
        <SwiperContainer>
            <NavigationButton onClick={() => swipeBack()}>
                <SwiperArrow />
            </NavigationButton>

            <SwiperContent>
                <Swiper
                    slidesPerView={slidersPerView}
                    direction="vertical"
                    spaceBetween={30}
                    modules={[Pagination, Navigation]}
                    id="small-model-swiper"
                    className='small-model-swiper'
                >

                    {images?.map((item, index) => (
                        <SwiperSlide key={index} onClick={() => openImageViewer(index)}>
                            <SmallPhotoListItem img={item.viewable_file_url} />
                        </SwiperSlide>
                    ))}

                </Swiper>
            </SwiperContent>

            <NavigationButton onClick={() => swipeNext()}>
                <SwiperArrow style={{ rotate: "180deg" }} />
            </NavigationButton>



            {isViewerOpen && (
                <ImageViewer

                    src={imagesFull}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}

        </SwiperContainer >
    )
}

export default ModelSmallSwiper

const SwiperContainer = styled.div`
display:flex;
flex-direction:column;
width:100%;
max-height:100%;
min-height:600px;
height:100%;
position:relative;
gap:10px;
svg{
    height:12px;
    width:22px;
}
`
const SwiperContent = styled.div`
position:realtive;
overflow:hidden;
height:100%;
`
const NavigationButton = styled.div`
border:none;
background:none;
display:flex;
justify-content:center;
align-items:center;
padding:5px;
cursor:pointer;

-webkit-box-shadow: 0px 0px 10px -5px ${color.primaryGreen}; 
box-shadow: 0px 0px 10px -5px ${color.primaryGreen};
border-radius:16px;
`
