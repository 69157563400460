import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './Context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <div id="recaptcha-container"></div>   {/* Firebase reCaptcha element */}
  </AuthContextProvider>

);
