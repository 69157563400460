import React, { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import { color } from "../../Assets/styles/colors";
import { useAuthService } from "../../Context/AuthContext";
import { notifyError, notifySucces } from "../Generic/Notifications";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Model/deleteImg.svg";

const DetailsEdit = ({ inputChangeHandler, inputValues, handleSaveButton, readyToSave }) => {
    return (
        <Container>
            <Title>
                <h1>Edit details</h1>
            </Title>
            <InputGroup>
                <h1>Name</h1>
                <input
                    name="name"
                    type="text"
                    placeholder="Type your name"
                    value={inputValues.name}
                    onChange={(e) => inputChangeHandler(e)}
                ></input>
            </InputGroup>
            <InputGroup>
                <h1>Email</h1>
                <input
                    name="email"
                    type="text"
                    placeholder="Type your email"
                    value={inputValues.email}
                    onChange={(e) => inputChangeHandler(e)}
                ></input>
            </InputGroup>

            <ButtonWrapper>
                <button disabled={!readyToSave} onClick={(e) => handleSaveButton(e)}>Save</button>
            </ButtonWrapper>
        </Container>
    );
};

const EditModal = ({ type, setIsModalOpen, isModalOpen, setIsLoading }) => {
    const ref = useRef();
    const { user, updateUser } = useAuthService();

    const initialInputValues = {
        name: user.name,
        email: user.email ? user.email : "",
        phone: user.phone_number,
    };

    const [inputValues, setInputvalues] = useState(initialInputValues);
    const [readyToSave, setReadyToSave] = useState(false)
    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setInputvalues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleSaveButton = () => {
        setIsLoading(true)
        updateUser(
            inputValues.name,
            inputValues.email,
            null,
            function (response) {
                setIsLoading(false)
                notifySucces("User updated");
                setIsModalOpen(false);
            },
            function (error) {
                setIsLoading(false)
                notifyError("Something wrong....")
                console.log("resp", error)
            }
        )
    };

    const checkUserDetailsChanged = () => {
        if (user.name !== inputValues.name ||
            user.email !== inputValues.email
        ) {
            setReadyToSave(true)
        }
        else {
            setReadyToSave(false)
        }

    }

    useEffect(() => {
        checkUserDetailsChanged();
    }, [inputValues])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isModalOpen]);

    return (
        <EditModalContainer>
            <EditCard ref={ref}>
                <CloseBtn onClick={() => setIsModalOpen(false)}><CloseIcon /></CloseBtn>
                <DetailsEdit
                    inputChangeHandler={inputChangeHandler}
                    inputValues={inputValues}
                    handleSaveButton={handleSaveButton}
                    readyToSave={readyToSave}
                />
            </EditCard>
        </EditModalContainer>
    );
};

export default EditModal;

const EditModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #67676791;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EditCard = styled.div`
  display: flex;
  flex-direction: column;
position:relative;
  gap: 40px;
  padding: 75px;
  border-radius: 20px;
  background: #fbfbfb;
  height: fit-content;
  width: 576px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;
const InputGroup = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${color.darkColor};
    margin-bottom: 30px;
  }
  input {
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 16px;
    font-family: PT Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  button {
    width: 100%;
    padding: 15px 0;
    color: ${color.white};
    background: ${color.primaryGreen};
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    &:disabled {
      opacity: 0.5;
    }
  }
`;

const Title = styled.div`
`
const CloseBtn = styled.button`
border:none;
background:none;
width:fit-content;
position:absolute;
right:30px;
top:30px;
cursor:pointer;
border-radius:100px;
display:flex;
align-items:center;
justify-content:center;
`