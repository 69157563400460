import { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../../Assets/styles/colors";
import OtpInput from "react-otp-input";

export const CodeNumberInput = ({
  setIsLoading,
  handleVerifyCode,
  wrongCode,
  handleResendOTP,
  readyToVerify,
  setReadyToverify,
}) => {

  const [otp, setOtp] = useState("");
  const checkReady = () => {
    if (otp.length !== 6) {
      setReadyToverify(false);
    } else {
      setReadyToverify(true);
      handleVerifyCode(otp);
    }
  };

  useEffect(() => {
    checkReady();
  }, [otp]);

  return (
    <CodeNumberInputContent>
      <TitleContainer>
        <h1>Now enter the code we sent you</h1>
      </TitleContainer>
      <PhoneNumberIpt>
        <OtpInput
          containerStyle={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          shouldAutoFocus
          inputStyle={wrongCode ? { borderColor: "red" } : {}}
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span> </span>}
          renderInput={(props) => <input id="opt-input" {...props} />}
        />
      </PhoneNumberIpt>
      {wrongCode && <WrongCodeStatus>
        <p>
          Sorry, the validation code is incorrect. Please try with another code.
        </p>
      </WrongCodeStatus>}

      <LoginButton
        disabled={!readyToVerify}
        id="verify-code"
        name="verify-code"
        onClick={() => handleVerifyCode(otp)}
      >
        Verify code
      </LoginButton>
      <ResendCodeContainer>
        <p>Didn't received the code? <span onClick={() => handleResendOTP()}>Resend Code</span> </p>
      </ResendCodeContainer>
    </CodeNumberInputContent>
  );
};
const PhoneNumberInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const CodeNumberInputContent = styled(PhoneNumberInputContent)``;
const WrongCodeStatus = styled.div`
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: ${color.errorColor};
  }
`;
const PhoneNumberIpt = styled.div`
  display: flex;
  width: 100%;
  input {
    min-width: 50px;
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 15px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
  }
`;
const TitleContainer = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }
`;

const LoginButton = styled.button`
  padding: 15px 0;
  color: ${color.white};
  background: ${color.primaryGreen};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  &:disabled{
    opacity:0.5;
  }
`;

const ResendCodeContainer = styled.div`
display:flex;
justify-content:center;
p{
  font-family: PT Sans;
font-size: 18px;
font-weight: 400;
line-height: 23px;
color:${color.darkColor};

}

span{
  color:${color.primaryGreen};
  font-weight: 700;
  cursor:pointer;
}
`