import React from 'react'
import styled from 'styled-components'
import { color } from '../../Assets/styles/colors'

const ProfileOption = ({ img, text, action, textStyle }) => {

    return (
        <OptionContainer onClick={() => action()}>
            {img}
            <h1 style={textStyle}>{text}</h1>
        </OptionContainer>
    )
}

export default ProfileOption
const OptionContainer = styled.div`
display:flex;
padding:12px 35px;
border:1px solid ${color.primaryGreen};
border-radius:10px;
align-items:center;
gap:15px;
cursor:pointer;

svg{
    height:50px;
    width:50px;
}

h1{
    font-family: PT Sans;
font-size: 18px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0px;
text-align: left;

}
`