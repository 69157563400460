import React from 'react'
import styled from 'styled-components'
import DashboardContent from '../Components/Dashboard/DashboardContent/DashboardContent'

const DashBoard = ({ setIsLoading }) => {

    return (
        <Container>
            <Content>
                <DashboardContent setIsLoading={setIsLoading} />
            </Content>
        </Container>

    )
}

export default DashBoard


const Container = styled.div`
display:flex;
flex-direction:column;
background:#FFFFFF;

`
const Content = styled.div`

`