export const sortValues = [
    {
        name: "Alphabetical asc",
        sortKey: "name",
        ascending: true
    },
    {
        name: "Alphabetical desc",
        sortKey: "name",
        ascending: false
    },
    {
        name: "Clicks number asc",
        sortKey: "clicks",
        ascending: true
    },
    {
        name: "Clicks number desc",
        sortKey: "clicks",
        ascending: false
    }
]