import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { color } from '../Assets/styles/colors'
import { ReactComponent as StatusGreen } from '../Assets/icons/Model/status-green.svg'
import { ReactComponent as StatusRed } from '../Assets/icons/Model/status-red.svg'
import { ReactComponent as EditIcon } from '../Assets/icons/Model/edit.svg'
import { ReactComponent as ProcentArrow } from '../Assets/icons/Model/procent-arrow.svg'
import { ReactComponent as BackIcon } from '../Assets/icons/arrow-back.svg'
import ActionButton from '../Components/Generic/ActionButton'
import ModelSmallSwiper from '../Components/Dashboard/ModelComponents/ModelSmallSwiper'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useProjectsService } from '../Context/ProjectsContext'
import { STATUS_FAILED } from '../Helper/Constants/status'
import EditModelModal from '../Components/Dashboard/DashboardContent/EditModal'
import { notifyError, notifySucces } from '../Components/Generic/Notifications'
import DeleteConfirmation from '../Components/Generic/ButtonWithConfirmation'
import "../Assets/styles/style.css"


const ProcessedStatus = ({ status }) => {
    return (
        <ProcessedStatusContainer status={status}>
            <>
                {status == STATUS_FAILED ? <StatusRed /> : <StatusGreen />}
                <h2>{status}</h2>
            </>
        </ProcessedStatusContainer>
    )

}

const ClicksToday = ({ clicks, procent }) => {
    let x = procent?.split("%");
    var number = 0;
    if (x) {
        number = parseInt(x[0])
    }


    return (
        <TodayClicksContainer>
            <div>
                <h1>CLICKS TODAY</h1>
            </div>
            <CTStats>
                <h2>{clicks}</h2>
                {procent &&
                    <ProcentCell className={number < 0 ? "error" : ""}>
                        <p>{procent}</p>
                        <ProcentArrow className={number < 0 ? "rotate" : ""} />
                    </ProcentCell>
                }


            </CTStats>
        </TodayClicksContainer>
    )
}
const Clicks = ({ text, clicksNumber, type }) => {
    return (
        <GeneralClicksContainer type={type}>
            <p>{text}</p>
            <h1>{clicksNumber}</h1>

        </GeneralClicksContainer>
    )

}

const ModelPage = ({ setIsLoading }) => {

    const location = useLocation();
    const params = useParams();
    const [currentProject, setCurrentProject] = useState(null);
    const [editItemModal, setEditItemModal] = useState(false);
    const { getProject, deleteProject, editProject, viewObject } = useProjectsService();
    const navigate = useNavigate();

    const goToManagePictures = () => {
        navigate(`/home/model/${currentProject.id}/manage-images`, { state: currentProject })
    }

    const handleEditButton = () => {
        setEditItemModal(true)
    }

    const handleEditProject = (projectId, name, description) => {
        editProject(
            projectId,
            name,
            description,
            function (response) {
                loadProject();
                setEditItemModal(false);
                notifySucces("Edit successful");
            },
            function (error) {
                console.log(error);
            }
        )
    }

    const handleDeteleButton = () => {
        deleteProject(
            currentProject.id,
            () => {
                navigate("/home")
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleShareLink = () => {
        let link = ` https://api.armerc.com/app/objects/view/${currentProject.identifier}`
        navigator.clipboard.writeText(link);
        notifySucces("Link copied")

    }

    const loadProject = () => {
        setIsLoading(true);
        getProject(
            params.id,
            function (response) {
                setCurrentProject(response.data.project);
                setIsLoading(false);
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong...")
                setIsLoading(false);
                navigate("/home");

            },
        )
    }


    useEffect(() => {
        loadProject();
    }, [location])

    return (
        <div className='container-1440' style={{ minHeight: "600px" }}>

            <LocalNavigation>

                <History>
                    <BackButton onClick={() => navigate("/home")}>
                        <BackIcon />
                    </BackButton>
                    <p>Home / <span>{currentProject?.name} </span></p>
                </History>

            </LocalNavigation>
            <ModelContentContainer>
                {currentProject?.images.length > 1 ?
                    <>
                        <PhotoListContainer>


                            <ModelSmallSwiper images={currentProject?.images} />
                            <ActionButton
                                text={"Edit photos"}
                                type={"green"}
                                action={goToManagePictures}
                            />


                        </PhotoListContainer>

                        <ModelContainer>
                            <model-viewer
                                src={currentProject?.model?.generic_model_url}
                                poster={currentProject?.cover?.url}
                                shadow-intensity="1" ar camera-controls touch-action="pan-y"
                                alt="A 3D model carousel">

                            </model-viewer>
                        </ModelContainer>
                    </>
                    :
                    <EmptyModelContainer>
                        <h1>Add some images to build a model</h1>
                        <HugeAddButton onClick={() => goToManagePictures()}>
                            Add images
                        </HugeAddButton>
                    </EmptyModelContainer>
                }
                <DetailsContainer>
                    <DescriptionDetails>
                        <TextContainer>
                            <h1>{currentProject?.name}</h1>
                            <p>{currentProject?.description}</p>
                            <ProcessedStatus status={currentProject?.status} />
                        </TextContainer>
                        <div style={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
                            <EditButton onClick={() => handleEditButton()}><EditIcon />Edit</EditButton>
                        </div>

                    </DescriptionDetails>
                    {currentProject?.images?.length > 0 && <StatsDetails>
                        <h1>Model stats</h1>
                        <StatsRow>
                            <ClicksToday clicks={currentProject?.stats.day} procent={currentProject?.stats.day_by_day_percentage} />
                            <Clicks text={"CLICKS PER MONTH"} clicksNumber={currentProject?.stats.month} type={"dark"} />
                            <Clicks text={"TOTAL CLICKS"} clicksNumber={currentProject?.stats.total} type={"green"} />
                        </StatsRow>

                    </StatsDetails>}

                    <ButtonsContainer>
                        {currentProject?.images?.length > 0 &&
                            <ActionButton
                                text={"Share link"}
                                type={"dark"}
                                action={handleShareLink}
                            />}
                        <DeleteConfirmation
                            handleRemoveItem={handleDeteleButton}
                        />

                    </ButtonsContainer>
                </DetailsContainer>



            </ModelContentContainer>
            {
                editItemModal && (
                    <EditModelModal
                        type={"edit"}
                        action={handleEditProject}
                        setIsModalOpen={setEditItemModal}
                        isModalOpen={editItemModal}
                        currentProject={currentProject}
                    />
                )
            }
        </div >
    )
}

export default ModelPage


const ModelContentContainer = styled.div`
display:flex;
gap:30px;
max-height:calc(100vh - 177px);
height:calc(100vh - 177px);
min-height:600px;
position:relative;
padding-bottom:10px;

`
const PhotoListContainer = styled.div`
min-width:130px;
display:flex;
flex-direction:column;
gap:30px;
padding:5px;
height:100%;
`
const ModelContainer = styled.div`
display:flex;
width:600px;
max-height:800px;
min-width:600px;
position:relative;
overflow:hidden;
model-viewer{
    min-width:600px;
    height:100%;
}
`
const DetailsContainer = styled.div`
min-width:510px;
max-width:510px;

width:100%;
display:flex;
flex-direction:column;
overflow:hidden;
position:relative;
`
const DescriptionDetails = styled.div`
display:flex;
gap:30px;
min-height:180px;
justify-content: space-between;
position:realtive;
`
const StatsDetails = styled.div`
margin-top:40px;
`
const TextContainer = styled.div`
display:flex;
flex-direction:column;
row-gap:20px;
border-bottom:1.5px solid #92969614;
padding-bottom:40px;
width:100%;
max-width:400px;
max-height:430px;

h1{
    font-family: PT Sans;
    font-size: 28px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color:${color.darkColor};
}
p{
    overflow:auto;
    max-height:250px;
    font-family: PT Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color:${color.darkColor};
    white-space:wrap;
    text-overflow:ellipsis;

}

`
const EditButton = styled.button`
display:flex;
align-items:center;
gap:10px;
border:none;
background:none;
font-family: PT Sans;
font-size: 16px;
font-weight: 700;
line-height: 21px;
cursor:pointer;
svg{
    height:21px;
}
`
const ProcessedStatusContainer = styled.div`
display:flex;
border:1px solid ${props => props.status === STATUS_FAILED ? color.errorColor : color.primaryGreen};
border-radius:14px;
background:${props => props.status === STATUS_FAILED ? "#F2FBFB" : color.white};
padding:10px 20px;
gap:10px;
h2{
    font-family: PT Sans;
font-size: 16px;
font-weight: 700;
line-height: 24px;
    text-transform:capitalize;
    color:${(props) => props.status == STATUS_FAILED ? color.errorColor : color.primaryGreen};
}
width:fit-content;
`
const StatsRow = styled.div`
display:flex;
gap:25px;
margin-top:25px;
`
const TodayClicksContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
padding:12px;
border:1px solid ${color.primaryGreen};
border-radius:16px;
h1{
font-family: PT Sans;
font-size: 10px;
font-weight: 700;
line-height: 13px;
text-align: left;
color:#262E2E99;
}
`
const CTStats = styled.div`
display:flex;
gap:25px;
align-items:center;
.error{
    background:${color.errorColor};
}
.rotate{
    rotate:180deg;
}
h2{
    font-family: PT Sans;
font-size: 28px;
font-weight: 700;
line-height: 36px;
text-align: left;
color:${color.darkColor};
}
`
const ProcentCell = styled.div`
display:flex;
background:${color.primaryGreen};
border-radius:16px;
align-items:start;
padding:12px;
gap:5px;
p{
font-family: PT Sans;
font-size: 14px;
font-weight: 700;
line-height: 18px;
text-align: center;
color:${color.white};
}
`
const GeneralClicksContainer = styled.div`
display:flex;
flex-direction:column;
gap:5px;
padding:12px;
border-radius:16px;
min-width:127px;
background:${props => props.type === "dark" ? color.darkColor : color.primaryGreen};
h1{
font-family: PT Sans;
font-size: 28px;
font-weight: 700;
line-height: 36px;
text-align: left;
color:${color.white};
}
p{
font-family: PT Sans;
font-size: 10px;
font-weight: 700;
line-height: 13px;
text-align: left;
color: #FFFFFFB2;

}
`
const ButtonsContainer = styled.div`
display:flex;
gap:10px;
margin-top:40px;
`
const LocalNavigation = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:20px 0;
`
const BackButton = styled.button`
width:fit-content;
border:none;
background:none;
cursor:pointer;
display:flex;
align-items:center;
`
const History = styled.div`
display:flex;
gap:10px;
p{
    font-family: PT Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
color:#B6B6B6;
}
span{
   color:${color.darkColor};
}
`

const ProgressBar = styled.div`
height:4px;
background:${color.primaryGreen};
border-radius:50px;
`
const EmptyModelContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:start;
align-items:center;
gap:50px;
padding-top:10%;
`

const HugeAddButton = styled.button`
box-shadow: 0px 4px 10px 0px #306d6d4d;
display: flex;
justify-content: center;
cursor: pointer;
background: ${color.primaryGreen};
border-radius: 100px;
color: ${color.white};
font-family: PT Sans;
font-size: 18px;
font-weight: 700;
line-height: 24px;
text-align: center;
padding: 15px 60px;
border: none;
:disabled {
  opacity: 0.4;
  cursor: inherit;
}
`