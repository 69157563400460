import React from "react";
import styled from "styled-components";
import { color } from "../../../Assets/styles/colors";
import { ReactComponent as StatusReady } from "../../../Assets/icons/Model/status-ready.svg";
import { ReactComponent as StatusFailed } from "../../../Assets/icons/Model/status-failed.svg";
import { ReactComponent as StatusProcessing } from "../../../Assets/icons/Model/status-processing.svg";

import { ReactComponent as NewAsset } from "../../../Assets/icons/Model/newAsset.svg";
import { ReactComponent as FailedAsset } from "../../../Assets/icons/Model/failedAsset.svg";

import {
    STATUS_FAILED,
    STATUS_PROCESSING,
    STATUS_READY,
} from "../../../Helper/Constants/status";
import { MoonLoader } from "react-spinners";
import "../../../Assets/styles/style.css";
const LoaderElement = ({ children }) => {
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-32px", left: "-38px" }}>
                <MoonLoader color="#36d7b7" size={100} speedMultiplier={0.2} />
            </div>
            {children}
        </div>
    );
};
const ModelItem = ({ goToModel, project }) => {
    var cover = <NewAsset />;
    var status = <StatusReady />;

    if (project.status === STATUS_PROCESSING) {
        cover = (
            <LoaderElement>
                <NewAsset />
            </LoaderElement>
        );
        status = <StatusProcessing />;
    }

    if (project.status === STATUS_FAILED) {
        status = <StatusFailed />;
        cover = <FailedAsset />;
    }

    if (project.status === STATUS_READY) {
        cover = (
            <ReadyContainer>
                <img src={project?.cover?.url} alt="cover" />
            </ReadyContainer>
        );
    }

    return (
        <ModelContainer onClick={() => goToModel(project.id, project)}>
            <CoverContainer status={project.status}>
                <Details>
                    <Status>{status}</Status>
                </Details>
                {cover}
            </CoverContainer>
            <TextContainer>
                <p>{project.name}</p>
            </TextContainer>
        </ModelContainer>
    );
};

export default ModelItem;
const ModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 230px;
  max-width:240px;
  cursor: pointer;

`;
const CoverContainer = styled.div`
  border: 1px solid
    ${(props) =>
        props.status !== STATUS_FAILED ? color.primaryGreen : color.errorColor};
  border-radius: 45px;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
  max-width: 240px;
  min-width: 240px;
  position: relative;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  img {
    height: 100%;
    width:100%;
    object-fit: cover;
  }
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: center;

  p {
      width:100%;
    font-family: PT Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: ${color.darkColor};
  }
`;
const Details = styled.div`
position:absolute;
top:0;
left:0;
right:0;
display:flex;
margin-top:20px;
margin-left:15px;
justify-content:center
align-items:center;
width:100%;
min-height:40px;
min-width:40px;
max-width:200px;

`;
const Status = styled.div`
  display: flex;
`;

const ReadyContainer = styled.div`
  height: 100%;

`;
