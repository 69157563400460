
import { createContext, useState, useEffect, useContext, useCallback } from "react";


import { onAuthStateChanged, signInWithPhoneNumber, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { createUserApi } from "../API/users/createUser";
import { getCurrentUserApi } from "../API/users/getCurrentUser";
import LocalStorage from "../Helper/LocalStorage";
import { updateUserApi } from "../API/users/updateUser";
import { refreshTokenApi } from "../API/users/refreshToken";
import { deleteUserApi } from "../API/users/deleteUser";

export const AuthContext = createContext({
    user: [],
    isAuthenticated: [],
    firebaseToken: [],
    signInWithPhone: () => { },
    verifyOtpCodeAndSignIn: () => { },
    createUser: () => { },
    getCurrentUser: () => { },
    singOut: () => { },
    updateUser: () => { },
    refreshToken: () => { },
    deleteUser: () => { },
});

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [isAuthenticated, setIsAuthentichated] = useState(false);
    const [firebaseToken, setFirebaseAuthToken] = useState(null);


    const handleLoginUser = (user) => {
        setUser(user);
        setIsAuthentichated(true);
    }

    const handleLogoutUser = () => {
        setUser(null);
        setIsAuthentichated(false);
    }


    const signInWithPhone = (phoneNumber, appVerifier, onSucces, onError) => {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                onSucces(confirmationResult);
                //   window.confirmationResult = confirmationResult;
                // ...
            }).catch((error) => {
                console.log(error);
                onError(error)
            });
    }

    const verifyOtpCodeAndSignIn = (confirmationResult, code, onSucces, onError) => {
        confirmationResult.confirm(code).then((result) => {
            const user = result.user;
            onSucces(result);
            // ...
        }).catch((error) => {
            onError(error);
            // User couldn't sign in (bad verification code?)
            // ...
        });

    }
    const refreshToken = useCallback(async (
        refreshToken,
        onSucces,
        errorCallback) => {
        try {
            await refreshTokenApi(
                refreshToken,
                function (response) {
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback();
        }
    });


    const authObserver = () => {
        onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = authUser.uid;
                LocalStorage.setAuthenthicationToken(authUser.accessToken);
                LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);
                setFirebaseAuthToken(authUser.accessToken);
                // handleLoginUser(user);
                if (!user) {
                    getCurrentUser(function () { }, function () { })
                }

            } else {
                handleLogoutUser();
                // User is signed out
                // ...
            }
        });

    }

    const singOut = () => {
        signOut(auth).then(() => {
            handleLogoutUser();
        }).catch((error) => {
            console.log(error)
        })

    };

    const getCurrentUser = useCallback(async (onSucces, errorCallback) => {
        try {
            await getCurrentUserApi(
                function (response) {
                    handleLoginUser(response.data.user);
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback();
        }
    });

    const createUser = useCallback(async (name, email, fcm_token, onSucces, errorCallback) => {
        try {
            await createUserApi(
                name,
                email,
                fcm_token,
                function (response) {
                    handleLoginUser(response.data.user);
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const updateUser = useCallback(async (name, email, fcm_token, onSucces, errorCallback) => {
        try {
            await updateUserApi(
                name,
                email,
                fcm_token,
                function (response) {
                    handleLoginUser(response.data.user);
                    onSucces(response)
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const deleteUser = useCallback(async (onSucces, errorCallback) => {
        try {
            await deleteUserApi(
                function (response) {
                    singOut();
                    onSucces(response)
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    useEffect(() => {
        authObserver();
    }, [])

    return (
        <AuthContext.Provider value={{
            user,
            isAuthenticated,
            firebaseToken,
            signInWithPhone,
            verifyOtpCodeAndSignIn,
            createUser,
            getCurrentUser,
            singOut,
            updateUser,
            refreshToken,
            deleteUser
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthService = () => useContext(AuthContext);

export default AuthContextProvider;
