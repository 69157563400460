import { PATCH } from "../API";
let source = null;

export const editProjectApi = (projectId, name, description, onSuccess, onError) => {
    const data = {
        name: name,
        description: description,
    };

    source = PATCH(`/api/v1/projects/${projectId}`, data, onSuccess, onError, true, source);
};