import styled from "styled-components";
import { color } from "../../Assets/styles/colors";
import { useState } from "react";

export const NewUserInput = ({ setIsLoading, handleCreateNewUser }) => {
    const initialInputValues = {
        name: "",
        email: "",

    };
    const [inputValues, setInputvalues] = useState(initialInputValues);

    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setInputvalues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleButtonClick = () => {
        handleCreateNewUser(inputValues.name, inputValues.email, null)
    }

    return (
        <NewUserInputContainer>
            <InputGroup>
                <h1>Edit name</h1>
                <input
                    name="name"
                    type="text"
                    placeholder="Type your name"
                    value={inputValues.name}
                    onChange={(e) => inputChangeHandler(e)}
                ></input>
            </InputGroup>

            <InputGroup>
                <h1>Edit email</h1>
                <input
                    name="email"
                    type="text"
                    placeholder="Type your email"
                    value={inputValues.email}
                    onChange={(e) => inputChangeHandler(e)}
                ></input>
            </InputGroup>
            <LoginButton

                onClick={() => handleButtonClick()}
            >
                Continue
            </LoginButton>
        </NewUserInputContainer>
    )


}

const NewUserInputContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
gap: 40px;
`
const LoginButton = styled.button`
  padding: 15px 0;
  color: ${color.white};
  background: ${color.primaryGreen};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;
const InputGroup = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${color.darkColor};
    margin-bottom: 30px;
  }
  input {
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 16px;
    font-family: PT Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
`;