import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { color } from '../../Assets/styles/colors';
import { myCountryCodesObject } from '../../Helper/Constants/CountryCodes';
import { getCountryRoundFlag } from '../../Helper/getFlag';


const CountryCodeMenu = ({ value, setValue }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [search, setSearch] = useState('')
    const ref = useRef();

    const filterItems = (list, filterTerm) => {
        if (filterTerm == "") {
            return list
        } else {
            const filtered = list.filter((item) => item.countryName.toLowerCase().includes(filterTerm.toLowerCase()) ||
                item.countryCallingCode.toLowerCase().includes(filterTerm.toLowerCase()));
            return filtered;
        }


    }
    const handleSelectItem = (item) => {
        setValue(item);
        setIsMenuOpen(false)
    }

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const handleChange = (e) => {
        setSearch(e.currentTarget.value)
    }

    const handleKey = (e) => {
        if (e.code === "Escape") {
            setSearch("");
            setIsMenuOpen(false)
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);


    return (
        <CountryCodeMenuWrapper ref={ref}>
            <SelectedValue onClick={handleMenuOpen}>
                <FlagWrapper>
                    <img src={getCountryRoundFlag(value.countryCode)} alt="flag" loading='lazy' />
                </FlagWrapper>
                <PrefixWrapper>
                    <p>+{value.countryCallingCode}</p>
                </PrefixWrapper>
            </SelectedValue>
            {isMenuOpen &&
                <CodesList>
                    <ListItem>
                        <SearchInList
                            autoFocus
                            value={search}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e) => handleKey(e)}
                            placeholder='Search'
                        ></SearchInList>
                    </ListItem>
                    {filterItems(myCountryCodesObject, search).map((item, index) => (
                        <ListItem key={index} onClick={(e) => handleSelectItem(item)} id={item.countryName}>
                            <FlagWrapper>
                                <img src={getCountryRoundFlag(item.countryCode)} alt="flag" loading='lazy' />
                            </FlagWrapper>
                            <NameWrapper >
                                <p>{item.countryName} </p> <p>+{item.countryCallingCode}</p>
                            </NameWrapper>

                        </ListItem>
                    ))}
                </CodesList>}

        </CountryCodeMenuWrapper>
    )
}

export default CountryCodeMenu

const CountryCodeMenuWrapper = styled.div`
display:flex;
position:relative;

`
const SelectedValue = styled.div`
border-radius:15px;
box-shadow: 0px 0px 20px 0px #262E2E1A;
width:90px;
height:54px;
display:flex;
align-items:center;
justify-content:center;
gap:5px;
cursor:pointer;
padding:10px 20px;
`
const PrefixWrapper = styled.div`
p{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color:${color.darkColor};
    }
`
const FlagWrapper = styled.div`
overflow:hidden;
height:21px;
width:21px;
min-width:21px;
min-height:21px;
border-radius: 50%;
position:relative;
justify-content:center;
align-items:center;
display:flex;
img{
    height:100%;
    width:100%;
}

`
const CodesList = styled.div`
position:absolute;

max-height:450px;
overflow:auto;
width:300px;

background:${color.white};
top:45px;

`
const ListItem = styled.div`
padding:10px 20px;
display:flex;
gap:5px;
cursor:pointer;
`
const NameWrapper = styled.div`
display:flex;
justify-content:space-between;
width:100%;
p{
    white-space: nowrap;
    max-width:180px;
    overflow:hidden;
    text-overflow:ellipsis;
font-size: 17px;
font-weight: 400;
line-height: 22px;
letter-spacing: 1px;
text-align: left;

}
`
const SearchInList = styled.input`
border:none;
background:none;
border-bottom:1px solid ${color.primaryGreen};
padding:10px;
width:100%;
font-size: 17px;
font-weight: 400;
line-height: 22px;
letter-spacing: 1px;
text-align: left;
`