import { POST } from "../API";
let source = null;

export const createProjectApi = (name, description, model_type, onSuccess, onError) => {
    const data = {
        name: name,
        description: description,
        model_type: model_type,
    };

    source = POST("/api/v1/projects/create", data, onSuccess, onError, true, source);
};