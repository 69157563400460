/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { color } from "../Assets/styles/colors";
import { ReactComponent as DeleteIcon } from "../Assets/icons/Model/deleteImg.svg";
import { ReactComponent as BackIcon } from '../Assets/icons/arrow-back.svg'
import ActionButton from "../Components/Generic/ActionButton";
import NoPhotosPlaceholder from "../Assets/images/no-photos-placeholder.webp";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectsService } from "../Context/ProjectsContext";
import { notifyError, notifySucces } from "../Components/Generic/Notifications";
import LoadBar from "../Components/Generic/LoadBar";


function ManagePicturesPage({ setIsLoading }) {

    const [files, setFiles] = useState([]);

    const [droppedFiles, setDroppedFiles] = useState([]);
    const [filesForUpload, setFilesForUpload] = useState([]);

    const [maxFilesToUpload, setMaxFilesToUpload] = useState(0)
    const [filesUploadedNr, setFilesUploadedNr] = useState(0)

    const [currentProject, setCurrentProject] = useState(null);
    const { addImageToProject, deleteImageFromProject, getProject } = useProjectsService();
    const params = useParams();
    const navigate = useNavigate();

    const handleUploadProgress = (name, progressValue) => {
        setDroppedFiles((prevData) => {
            const newData = prevData?.map((item) => {
                if (item.name === name) {
                    Object.assign(item, {
                        progress: progressValue
                    })
                }
                return item;
            });
            return newData;
        });
    };

    const filterUploaded = (uploadedImg, allDroppedFiles) => {
        let filtered = allDroppedFiles.filter((item) => item.name !== uploadedImg.name)
        setDroppedFiles(filtered);

    }

    const mapCurrentFiles = (files) => {
        const newFiles = files?.map((item) => {
            let splitted = item.url.split("/");
            let newItem = {
                ...item,
                name: splitted[splitted.length - 1],
            };
            return newItem;
        });

        return newFiles;
    };

    const mapOneFile = (file) => {
        let splitted = file.url.split("/");
        let newStr = splitted[splitted.length - 1].replace(/[^A-Z0-9/.]+/ig, " ");
        const newFile = {
            ...file,
            name: newStr
        }
        return newFile
    }


    const uploadImages = (filesForUpload) => {
        let max = filesForUpload.length;
        let counter = 0;
        setMaxFilesToUpload(max);
        filesForUpload.forEach((item, index) => {
            let formData = new FormData();
            formData.append("image", item);
            addImageToProject(
                currentProject.id,
                formData,
                function (response) {
                    counter += 1;
                    setFilesUploadedNr(counter)
                    filterUploaded(item, droppedFiles);
                    let newItem = mapOneFile(response.data.message);
                    setFiles((prevData) => {
                        return [
                            ...prevData,
                            newItem
                        ]
                    })

                    if (counter === max) {
                        setDroppedFiles([])
                        setFilesForUpload([])
                        setMaxFilesToUpload(0);
                        setFilesUploadedNr(0);
                        notifySucces("All images uploaded")
                    }
                },
                function (error) {
                    setFiles((prevData) => {
                        return [
                            ...prevData,
                        ]
                    })
                    notifyError("Something wrong")
                    setDroppedFiles([])
                    setFilesForUpload([])
                    setMaxFilesToUpload(0);
                    setFilesUploadedNr(0);
                    console.log(error, "error");
                },
                function (progress) {
                    handleUploadProgress(item.name, progress);

                }
            );
        })
    }

    const onDrop = useCallback((acceptedFiles) => {
        setDroppedFiles(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );

        setFilesForUpload(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );



    });

    const handleRemoveImage = (projectId, imageId) => {
        deleteImageFromProject(
            projectId,
            imageId,
            function (response) {
                handleGetProject();
            },
            function (error) {
                console.log(error, "deleteerror")
            }
        )
    }
    const handleGetProject = () => {
        setIsLoading(true);
        getProject(
            params.id,
            function (response) {
                setCurrentProject(response.data.project);
                setIsLoading(false);
            },
            function (error) {
                console.log(error);
                setIsLoading(false);
                notifyError("Something wrong");

            }
        )
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop,
    });

    const checkImageType = (file) => {
        const split = file.name.split(".")
        return split[split.length - 1]
    }
    const currentThumbs = files.sort((a, b) => a.name > b.name ? 1 : -1).map((file, index) => (
        <ThumpContainer key={index}>
            <Thumb>
                <ThumbBtn>
                    <button onClick={() => handleRemoveImage(currentProject.id, file.id)} >
                        <DeleteIcon />
                    </button>
                </ThumbBtn>
                <ThumbInner>
                    <img
                        src={file.viewable_file_url || file.url}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                        }}
                        alt="thumb"
                    />
                </ThumbInner>
            </Thumb>
            <p>
                {file.name}
            </p>

        </ThumpContainer>
    ));

    const uploadingThumbs = droppedFiles.map((file, index) => {
        if (checkImageType(file) === "HEIC") {
            return (<ThumpContainer key={index}>
                <Thumb>
                    <ThumbBtn>
                        <button >
                            <DeleteIcon />
                        </button>
                    </ThumbBtn>
                    <ThumbInner>
                        <p>Image</p>
                    </ThumbInner>

                </Thumb>
                <p>Uploading...</p>
                <progress value={file.progress} max={100} />
            </ThumpContainer>)


        } else {
            return (
                <ThumpContainer key={index}>
                    <Thumb>
                        <ThumbBtn>
                            <button >
                                <DeleteIcon />
                            </button>
                        </ThumbBtn>
                        <ThumbInner>
                            <img
                                src={file.preview || file.url}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                }}
                                alt="thumb"
                            />
                        </ThumbInner>

                    </Thumb>
                    <p>Uploading...</p>
                    <progress value={file.progress} max={100} />
                </ThumpContainer>
            )

        }
    })

    // <ThumpContainer key={index}>
    //     <Thumb>
    //         <ThumbBtn>
    //             <button >
    //                 <DeleteIcon />
    //             </button>
    //         </ThumbBtn>
    //         <ThumbInner>
    //             <img
    //                 src={file.preview || file.url}
    //                 // Revoke data uri after image is loaded
    //                 onLoad={() => {
    //                     URL.revokeObjectURL(file.preview);
    //                 }}
    //                 alt="thumb"
    //             />
    //         </ThumbInner>

    //     </Thumb>
    //     <p>Uploading...</p>
    //     <progress value={file.progress} max={100} />
    // </ThumpContainer>
    // ));


    const calculateUploadProgress = (uploadedFiles, totalFiles) => {
        return (uploadedFiles * 100) / totalFiles;
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(() => {
        if (!currentProject) {
            return
        }
        setFiles(mapCurrentFiles(currentProject.images));
    }, [currentProject]);

    useEffect(() => {
        handleGetProject();
    }, [window.location])

    useEffect(() => {
        if (filesForUpload.length > 0) {
            uploadImages(filesForUpload);
        }
    }, [filesForUpload])


    return (
        <div className="container-1440" >

            <Wrapper>

                <BottomDetailsWrapper>
                    <History>
                        <BackButton onClick={() => navigate(`/home/model/${currentProject.id}`)}>
                            <BackIcon />
                        </BackButton>
                        <p>Home /</p>
                        {currentProject?.name && <><p>{currentProject.name} /</p><p><span>Pictures</span></p></>}

                    </History>

                    <ActionButton
                        style={{ maxWidth: "150px", maxHeight: "60px" }}
                        action={open}
                        text={"Select files"}
                    />

                </BottomDetailsWrapper>
                {maxFilesToUpload > 0 && <LoadBar
                    finishedItems={filesUploadedNr}
                    totalItems={maxFilesToUpload}
                    completed={calculateUploadProgress(filesUploadedNr, maxFilesToUpload)}
                />}

                <DropzoneContainer>
                    <InputZone {...getRootProps()}>
                        {(droppedFiles.length > 0 || files.length > 0) && (
                            <PreviewContainer id="target">
                                {currentThumbs}
                                {uploadingThumbs}
                            </PreviewContainer>
                        )}
                        {files.length === 0 && droppedFiles.length === 0 && (
                            <PlaceholderImgContainer>
                                <img src={NoPhotosPlaceholder} alt="placeholder" />
                                <h1>No photos</h1>
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </PlaceholderImgContainer>
                        )}
                        <input {...getInputProps()} />


                    </InputZone>
                </DropzoneContainer>
            </Wrapper>



        </div>
    );
}

export default ManagePicturesPage;
const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap:10px;
width:100%;
padding-bottom:20px;
position:relative;
`
const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius:20px;
  min-height: 600px;
  height: 100%;
  justify-content: space-between;
  max-height:calc(100vh - 210px);
`;
const InputZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow: auto;
  border: 1px solid #b6b6b6;
  border-radius: 20px;
  padding: 20px;
  gap: 40px;
`;
const Thumb = styled.div`
  display: flex;
  border-radius: 40px;
  border: 1px solid ${color.primaryGreen};
  margin-bottom: 8px;
  margin-right: 8px;
  width: 220px;
  height: 220px;
overflow:hidden;
  position: relative;
`;
const ThumbInner = styled.div`
  position: absolute;
  display: flex;
  min-width: 0px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  top:0;
  bottom:0;
  left:0;
  right:0;
  img {
    width:100%;
    height:100%;
    object-fit:cover;
  }
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 100px;
  row-gap: 50px;
height:100%;
  overflow:auto;
`;

const ThumbBtn = styled.div`
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 100%;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;
const ThumpContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-family: PT Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: ${color.darkColor};
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const PlaceholderImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:20px;
  h1 {
    font-family: PT Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${color.darkColor};
  }
  img {
    max-height: 350px;
    object-fit: contain;
  }
`;
const BottomDetailsWrapper = styled.div`

display:flex;
justifty-content:space-between;
align-items:center;
  gap: 20px;

  div{
    width:100%;
  }
`;

const BackButton = styled.button`
width:fit-content;
border:none;
background:none;
cursor:pointer;
align-items:center;
display:flex;
`
const History = styled.div`
display:flex;
gap:10px;
align-items:center;
padding:20px 0;
p{
    font-family: PT Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
color:#B6B6B6;
}
span{
   color:${color.darkColor};
}
`