import React from 'react'
import styled from 'styled-components'
import { color } from '../../Assets/styles/colors'

const ActionButton = ({ text, type, action, style }) => {

    const normalButton =
        <NormalButton onClick={action} style={style}>
            {text}
        </NormalButton>

    const deleteButton =
        <DeleteButton onClick={action} style={style}>
            {text}
        </DeleteButton>
    const greenButton =
        <GreenButton onClick={action} style={style}>
            {text}
        </GreenButton>
    let btn = normalButton;

    if (type === "delete") {
        btn = deleteButton
    }
    if (type === "green") {
        btn = greenButton
    }

    return (
        btn
    )
}

export default ActionButton
const NormalButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
color:${color.white};
font-family: PT Sans;
font-size: 12px;
font-weight: 700;
line-height: 16px;
text-align: center;
padding:10px;
border-radius:68px;
border:none;
background:${color.darkColor};
min-width:50px;
min-height:38px;
width:100%;
cursor:pointer;
&:disabled{
    opacity:0.5;
}
`
const DeleteButton = styled(NormalButton)`
background:${color.white};
border:1px solid ${color.errorColor};
color:${color.errorColor};

`


const GreenButton = styled(NormalButton)`
background:${color.white};
border:1px solid ${color.primaryGreen};
color:${color.primaryGreen};
`