import { useState } from "react";
import styled from "styled-components";
import { color } from "../../Assets/styles/colors";
import CountryCodeMenu from "../Generic/CountryCodeMenu";

export const PhoneNumberInput = ({ setIsLoading, setCodeSent, handleSignIn }) => {
  const initialInputValues = {
    phoneNumber: "",
  };
  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [countryCode, setCountryCode] = useState({
    countryName: "Romania",
    countryCode: "RO",
    countryCallingCode: "40",
  });

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  let number = `+${countryCode.countryCallingCode}${inputValues.phoneNumber}`;

  return (
    <PhoneNumberInputContent>
      <TitleContainer>
        <h1>What's your phone number?</h1>
      </TitleContainer>
      <PhoneNumberContainer>
        <CountryCodeMenu value={countryCode} setValue={setCountryCode} />
        <PhoneNumberIpt>
          <input
            placeholder="Type your phone number"
            name="phoneNumber"
            value={inputValues.phoneNumber}
            onChange={(e) => inputChangeHandler(e)}
          />
        </PhoneNumberIpt>
      </PhoneNumberContainer>
      <LoginButton
        id="sign-in-button"
        name="sign-in-button"
        onClick={() => handleSignIn(number)}
      >
        Login
      </LoginButton>
    </PhoneNumberInputContent>
  );
};
const PhoneNumberInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const PhoneNumberContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
const PhoneNumberIpt = styled.div`
  display: flex;
  width: 100%;
  input {
    min-width: 50px;
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 15px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
  }
`;
const TitleContainer = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }
`;

const LoginButton = styled.button`
  padding: 15px 0;
  color: ${color.white};
  background: ${color.primaryGreen};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;