import React from 'react'
import styled from 'styled-components'
import { color } from '../../../Assets/styles/colors'
import "../../../Assets/styles/style.css"
import { ReactComponent as ArmercLogo } from "../../../Assets/icons/logo-dark.svg"
import { useNavigate } from 'react-router-dom'
const Header = () => {
    const navigate = useNavigate();
    const navigateToProfile = () => {
        navigate("/home/profile")
    }
    const navigateToHome = () => {
        navigate("/home")
    }
    return (
        <HeaderContainer className='container-1440'>
            <Logo onClick={() => navigateToHome()}> <ArmercLogo /> <h1>ARMERC</h1></Logo>
            <Profile>

                <ProfileButton onClick={() => navigateToProfile()}>
                    Profile
                </ProfileButton>
            </Profile>

        </HeaderContainer>
    )
}

export default Header
const HeaderContainer = styled.div`
display:flex;
min-height:3rem;
align-items:center;
justify-content:space-between;
padding-top:25px;
padding-bottom:25px;

`
const Logo = styled.div`
display:flex;
align-items:center;
gap:10px;
cursor:pointer;
h1{

font-size: 20px;
font-weight: 700;
line-height: 25px;
letter-spacing: -0.02em;
text-align: left;

}
`
const Profile = styled.div`
display:flex;

`
const ProfileButton = styled.div`
box-shadow: 0px 4px 10px 0px #306d6d4d;
display: flex;
justify-content: center;
cursor: pointer;
background: ${color.primaryGreen};
border-radius: 100px;
color: ${color.white};
font-family: PT Sans;
font-size: 18px;
font-weight: 700;
line-height: 24px;
text-align: center;
padding: 15px 60px;
border: none;
width: 100%;

:disabled {
  opacity: 0.4;
  cursor: inherit;
}
`