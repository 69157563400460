import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { color } from '../../Assets/styles/colors';
import { ReactComponent as SortIcon } from '../../Assets/icons/Model/sort-icon.svg'
import { sortValues } from '../../Helper/Constants/SortValues';


const SortMenu = ({ value, setValue }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const ref = useRef();

    const handleSelectItem = (item) => {
        setValue(item);
        setIsMenuOpen(false);
    }

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);


    return (
        <CountryCodeMenuWrapper ref={ref}>
            <SelectedValue onClick={handleMenuOpen}>
                <SortIcon /><p>Sort</p>
            </SelectedValue>
            {isMenuOpen &&
                <CodesList>

                    {sortValues.map((item, index) => (
                        <ListItem key={index} onClick={(e) => handleSelectItem(item)} id={item.countryName}>
                            <input checked={item.name === value.name} readOnly type="radio"></input>
                            <p>{item.name}</p>
                        </ListItem>
                    ))}
                </CodesList>}

        </CountryCodeMenuWrapper>
    )
}

export default SortMenu

const CountryCodeMenuWrapper = styled.div`
display:flex;
position:relative;
`
const SelectedValue = styled.div`
border-radius:15px;
display:flex;
align-items:center;
justify-content:center;
gap:5px;
cursor:pointer;
p{
    font-family: PT Sans;
font-size: 16px;
font-weight: 700;
line-height: 21px;
cursor:pointer;
color:${color.darkColor};
}
`
const CodesList = styled.div`
position:absolute;
max-height:450px;
overflow:auto;
width:max-content;
background:${color.white};
top:25px;
right:0;
z-index:2;
box-shadow: 0px 0px 20px 0px #262E2E1A;
border-radius:16px;
`
const ListItem = styled.div`
padding:10px;
display:flex;
gap:5px;
cursor:pointer;
`