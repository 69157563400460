import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../../../Assets/styles/colors";
import EditModelModal from "./EditModal";
import { ReactComponent as SortIcon } from '../../../Assets/icons/Model/sort-icon.svg'
import { notifyError, notifySucces } from "../../Generic/Notifications";
import { useProjectsService } from "../../../Context/ProjectsContext";
import ModelItem from "./ModelItem";
import { useNavigate } from "react-router-dom";
import SortMenu from "../../Generic/SortMenu";



const DashboardContent = ({ setIsLoading }) => {
    const [addNewModel, setAddNewModel] = useState(false);
    const { getProjects, createProject, projects } = useProjectsService();

    const [sortOption, setSortOption] = useState({
        name: "Alphabetical asc",
        sortKey: "name",
        ascending: true
    })

    const handleSort = () => {
        setSortOption((prev) => ({
            sortKey: prev.sortKey,
            ascending: !prev.ascending
        }))
    }
    const navigate = useNavigate();

    const loadProjects = () => {
        setIsLoading(true);
        getProjects(
            function () {
                setIsLoading(false);
                notifySucces("Projects loaded");

            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong");
                console.log(error);
            }
        );
    };

    const handleCreateProject = (
        name,
        description,
        modelType,
    ) => {
        setIsLoading(true);
        createProject(
            name,
            description,
            modelType,
            function (response) {
                setIsLoading(false);
                notifySucces("Project created");
                setAddNewModel(false);
                navigate(`/home/model/${response.data.project.id}/manage-images`)
                // loadProjects();
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong");
                console.log(error);
            }
        );
    };

    const handleShowModal = () => {
        setAddNewModel(!addNewModel);
    };

    const goToModel = (id, item) => {
        navigate(`/home/model/${id}`, { state: item })
    }

    const handleSortButton = () => {
        handleSort();
    }

    const sortFunction = (unsrotedArray) => {
        if (sortOption.sortKey === "name") {
            if (sortOption.ascending) {
                let sorted = unsrotedArray.sort((a, b) => a[sortOption?.sortKey].toLowerCase() > b[sortOption?.sortKey].toLowerCase() ? 1 : -1)
                return sorted
            } else {
                let sorted = unsrotedArray.sort((a, b) => a[sortOption?.sortKey].toLowerCase() < b[sortOption?.sortKey].toLowerCase() ? 1 : -1)
                return sorted
            }
        }
        if (sortOption.sortKey === "clicks") {
            if (sortOption.ascending) {
                let sorted = unsrotedArray.sort((a, b) => a.stats.total > b.stats.total ? 1 : -1)
                return sorted
            } else {
                let sorted = unsrotedArray.sort((a, b) => a.stats.total < b.stats.total ? 1 : -1)
                return sorted
            }
        }

    }
    useEffect(() => {
        if (addNewModel) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "initial";
        }
    }, [addNewModel]);

    useEffect(() => {
        loadProjects();
    }, []);

    return (
        <Container className="container-1440">
            {addNewModel && (
                <EditModelModal
                    setIsModalOpen={setAddNewModel}
                    isModalOpen={addNewModel}
                    action={handleCreateProject}
                />
            )}
            <OptionsBar>
                <HistoryContainer>
                    <p>Home</p>
                </HistoryContainer>
                <OptionsContainer>
                    <SortMenu value={sortOption} setValue={setSortOption} />
                    {/* <SortButton onClick={() => handleSortButton()}><SortIcon />Sort</SortButton> */}
                </OptionsContainer>

            </OptionsBar>
            <Content>
                {projects.length < 1 &&

                    <NoitemsContainer>
                        <h1>No projects</h1>
                        <p>Press the button below to add a new model</p>
                    </NoitemsContainer>
                }


                {sortFunction(projects)?.map((project, index) => (
                    <ModelItem
                        project={project}
                        goToModel={goToModel}
                        key={index}
                        modelName={project.name}
                        cover={project.cover}
                    />
                ))}
            </Content>

            <FloatingButton>
                <button onClick={() => handleShowModal()}>Add new model</button>
            </FloatingButton>
        </Container>
    );
};

export default DashboardContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
padding-bottom:60px;
`;
const FloatingButton = styled.div`
  position: fixed;
  bottom: 120px;
  left: calc(100vw - 50% - 120px);
  z-index: 1;
  button {
    box-shadow: 0px 4px 10px 0px #306d6d4d;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: ${color.primaryGreen};
    border-radius: 100px;
    color: ${color.white};
    font-family: PT Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding: 15px 60px;
    border: none;
    width: 240px;

    :disabled {
      opacity: 0.4;
      cursor: inherit;
    }
  }
`;

const Content = styled.div`
display:flex;
flex-wrap:wrap;
column-gap:110px;
row-gap:50px;
`
const OptionsBar = styled.div`
display:flex;
justify-content:space-between;
padding:20px 0;
`
const HistoryContainer = styled.div`
display:flex;
p{
    font-family: PT Sans;
font-size: 20px;
font-weight: 400;
line-height: 28px;
color:${color.darkColor};
}
`
const OptionsContainer = styled.div`
display:flex;

`
const SortButton = styled.button`
display:flex;
align-items:center;
gap:10px;
border:none;
background:none;
font-family: PT Sans;
font-size: 16px;
font-weight: 700;
line-height: 21px;
cursor:pointer;
svg{
    height:21px;
}
`
const NoitemsContainer = styled.div`
display:flex;
flex-direction:column;
gap:20px;
justify-content:center;
align-items:center;
height:100%;
width:100%;
margin-top:10%;
h1{
     font-family: PT Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${color.darkColor};
}
`