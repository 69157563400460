import React from 'react'
import styled from 'styled-components'
import { color } from '../../../Assets/styles/colors'

const SmallPhotoListItem = ({ img }) => {
    return (
        <Container img={img}>

        </Container>
    )
}

export default SmallPhotoListItem

const Container = styled.div`
border-radius:14px;
border:1px solid ${color.primaryGreen};
min-width:120px;
min-height:120px;
display:flex;
align-items:center;
padding:5px;
background-image:url(${(props) => props.img});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
`