import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { color } from "../../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../../Assets/icons/Model/deleteImg.svg";

const DetailsEdit = ({ inputChangeHandler, inputValues, buttonAction, initialInputValues }) => {
    const [ready, setReady] = useState(false);

    const checkEmptyFields = () => {
        if ((inputValues.modelName?.length < 3 ||
            inputValues.modelDescription?.length < 3) ||
            (inputValues?.modelName === initialInputValues?.modelName &&
                inputValues?.modelDescription === initialInputValues?.modelDescription)
        ) {
            setReady(false);
        } else {
            setReady(true);
        }
    }

    useEffect(() => {
        checkEmptyFields();
    }, [inputValues])




    return (
        <Container >
            <InputGroup>
                <h1>What’s your model name?</h1>
                <input
                    name="modelName"
                    type="text"
                    placeholder="Type your model name"
                    value={inputValues.modelName}
                    onChange={(e) => inputChangeHandler(e)}
                ></input>
            </InputGroup>
            <InputGroup>
                <h1>What’s your model description?</h1>
                <textarea

                    name="modelDescription"
                    type="text"
                    placeholder="Type model description"
                    value={inputValues.modelDescription}
                    onChange={(e) => inputChangeHandler(e)}
                ></textarea>
            </InputGroup>

            <ButtonWrapper>
                <button
                    disabled={!ready}
                    onClick={(e) => buttonAction(e)}>Continue</button>
            </ButtonWrapper>
        </Container>
    );
};


const EditModelModal = ({ type, setIsModalOpen, isModalOpen, action, currentProject }) => {
    const initialInputValues = {
        modelName: currentProject ? currentProject.name : "",
        modelDescription: currentProject ? currentProject.description : ""
    };

    const [inputValues, setInputvalues] = useState(initialInputValues);

    const ref = useRef();

    const handleCreateProject = () => {
        //createProject action
        action(
            inputValues.modelName,
            inputValues.modelDescription,
            "half"
        )
    };

    const handleEditProject = () => {
        //editProject action
        action(
            currentProject.id,
            inputValues.modelName,
            inputValues.modelDescription
        )
    }

    const inputChangeHandler = (e) => {

        const { name, value } = e.target;
        setInputvalues({
            ...inputValues,
            [name]: value,
        });
    };

    const create = <DetailsEdit
        inputChangeHandler={inputChangeHandler}
        inputValues={inputValues}
        buttonAction={handleCreateProject}
    />

    const edit = <DetailsEdit
        inputChangeHandler={inputChangeHandler}
        inputValues={inputValues}
        initialInputValues={initialInputValues}
        buttonAction={handleEditProject}
        type="edit"
    />

    var content = create;
    if (type === "edit") {
        content = edit
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isModalOpen]);
    return (
        <EditModalContainer>
            <EditCard ref={ref}>
                <CloseBtn onClick={() => setIsModalOpen(false)}><CloseIcon /></CloseBtn>
                {content}
            </EditCard>
        </EditModalContainer>
    );
};

export default EditModelModal;

const EditModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #67676791;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  z-index:2;
  height:100%;

`;
const EditCard = styled.div`
position:sticky;
top:50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 75px;
  border-radius: 20px;
  background: #fbfbfb;
height:fit-content;
  width: 576px;

`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const InputGroup = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${color.darkColor};
    margin-bottom: 30px;
  }
  input {
    height: 54px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding: 0 16px;
    font-family: PT Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
  textarea {
resize:none;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #306d6d4d;
    background: ${color.white};
    width: 100%;
    padding:  16px;
    font-family: PT Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  button {
    width: 100%;
    padding: 15px 0;
    color: ${color.white};
    background: ${color.primaryGreen};
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
     &:disabled {
        cursor:inherit;
      opacity: 0.5;
    }
  }
`;

const CloseBtn = styled.button`
border:none;
background:none;
width:fit-content;
position:absolute;
right:30px;
top:30px;
cursor:pointer;
border-radius:100px;
display:flex;
align-items:center;
justify-content:center;
`