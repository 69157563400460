import React, { useState } from 'react'
import styled from 'styled-components'
import ProfileOption from '../Components/Profile/ProfileOption'
import { ReactComponent as EditIcon } from "../Assets/icons/Profile/edit-profile-icon.svg"
import { ReactComponent as SupportIcon } from "../Assets/icons/Profile/support-profile-icon.svg"
import { ReactComponent as LogoutIcon } from "../Assets/icons/Profile/logout-profile-icon.svg"
import { useAuthService } from '../Context/AuthContext'
import EditModal from '../Components/Profile/EditModal'
import DeleteConfirmation from '../Components/Generic/ButtonWithConfirmation'
import { notifyError } from '../Components/Generic/Notifications'

const ProfilePage = ({ setIsLoading }) => {
    const { singOut, deleteUser } = useAuthService();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(false);

    const editAction = () => {
        setIsModalOpen(true)
    }
    const supportAction = () => {
        window.open("https://relleasy.notion.site/39ad4aa23af446a39ed3fd87a5f044a8?v=e443a254fcd345bc80c09574e33252f0")
    }
    const logoutAction = () => {
        singOut()
    }
    const deleteAction = () => {
        deleteUser(
            function (response) {

            },
            function (error) {
                console.log(error);
                notifyError("Something wrong")
            }
        );
    }

    const profileOptions = [
        {
            img: <EditIcon />,
            text: "Edit profile",
            action: editAction,
        },
        {
            img: <SupportIcon />,
            text: "Support",
            action: supportAction,
        },
        {
            img: <LogoutIcon />,
            text: "Log out",
            action: logoutAction,
        },
    ]

    return (
        <Container className='container-1440'>
            {isModalOpen && <EditModal
                type={modalType}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setIsLoading={setIsLoading}
            />}
            <ProfileContent>
                {profileOptions.map((option, index) => (
                    <ProfileOption
                        key={index}
                        text={option.text}
                        img={option.img}
                        action={option.action}
                        textStyle={option.textStyle}
                    />
                ))}
                <DeleteConfirmation
                    handleRemoveItem={deleteAction}
                    variant={"delete_profile"}
                />


            </ProfileContent>
        </Container>
    )
}

export default ProfilePage
const Container = styled.div`
`
const ProfileContent = styled.div`
display:flex;
flex-direction:column;
gap:40px;
margin-top:100px;
`